/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const helpListHelps = /* GraphQL */ `
  query HelpListHelps($limit: Int, $nextToken: String) {
    helpListHelps(limit: $limit, nextToken: $nextToken) {
      items {
        description
        id
        link
        order
        title
      }
      nextToken
    }
  }
`;
export const readableGetJob = /* GraphQL */ `
  query ReadableGetJob($id: ID!) {
    readableGetJob(id: $id) {
      items {
        createdAt
        id
        identity
        input
        itemId
        modelId
        name
        order
        output
        parent
        status
        type
        updatedAt
      }
    }
  }
`;
export const readableListJobs = /* GraphQL */ `
  query ReadableListJobs {
    readableListJobs {
      items {
        createdAt
        id
        identity
        name
        updatedAt
      }
      nextToken
    }
  }
`;
export const readableListModels = /* GraphQL */ `
  query ReadableListModels($limit: Int, $nextToken: String) {
    readableListModels(limit: $limit, nextToken: $nextToken) {
      items {
        default
        id
        name
        type
      }
      nextToken
    }
  }
`;
export const readableListPrintStyles = /* GraphQL */ `
  query ReadableListPrintStyles($limit: Int, $nextToken: String) {
    readableListPrintStyles(limit: $limit, nextToken: $nextToken) {
      items {
        css
        default
        id
        name
        type
      }
      nextToken
    }
  }
`;
export const sharedGetPreferences = /* GraphQL */ `
  query SharedGetPreferences {
    sharedGetPreferences {
      visualDensity
      visualMode
    }
  }
`;
export const translationListJobs = /* GraphQL */ `
  query TranslationListJobs($limit: Int, $nextToken: String) {
    translationListJobs(limit: $limit, nextToken: $nextToken) {
      items {
        contentType
        createdAt
        id
        jobIdentity
        jobName
        jobOwner
        jobStatus
        languageSource
        languageTargets
        sourceKey
        sourceStatus
        translateCallback
        translateKey
        translateStatus
      }
      nextToken
    }
  }
`;
